import { HaloCaches } from '@halo-data/cache';
import { haloInfiniteClient, xboxClient } from './clients';
import requestRetryPolicy from './match-query/request-policy';

const haloCaches = new HaloCaches(
  haloInfiniteClient,
  xboxClient,
  requestRetryPolicy
);
export const {
  usersCache,
  matchStatsCache,
  matchSkillsCache,
  playlistCache,
  matchPageCache,
  mapCache,
  gameVariantCache,
  playlistVersionCache,
  mapModePairCache,
} = haloCaches;
export default haloCaches;
